<script setup lang="ts">
import { AppTypography } from '@/components';
import { computed } from 'vue';
import { RequirementMetStatus } from '@/enums/RequirementMetStatus';

const props = withDefaults(
  defineProps<{
    title?: string;
    subtitle?: string;
    footerText?: string;
    icon?: string | boolean;
    iconColor?: string;
    iconContainerColor?: string;
    styles?: object | null;
    padding?: number | string;
    noGutter?: boolean;
    noShadow?: boolean;
    flat?: boolean;
    requirement?: string;
  }>(),
  {
    title: '',
    subtitle: '',
    footerText: '',
    iconContainerColor: '#EEEBFC',
    iconColor: 'primary',
    icon: '',
    styles: () => ({}),
    padding: 4,
    noGutter: false,
    noShadow: true,
    flat: true,
    requirement: '',
  }
);

const parentClasses = computed((): string => {
  const collection = [];

  collection.push(`pa-${props.padding}`);

  if (!props.noShadow) {
    collection.push(`app-form-card--shadow`);
  }

  return collection.join(' ');
});

const requirementLabel = computed((): string => {
  const requirements = {
    all: 'Requirements Met',
    some: 'Requirement Met (some)',
    none: 'Requirements not met',
  } as any;

  return requirements[props.requirement] ?? '';
});
</script>

<template>
  <v-card class="app-form-card" :class="parentClasses" :flat="flat">
    <div class="d-flex align-center">
      <div v-if="icon" class="icon-container mr-2" :style="`background-color: ${iconContainerColor}`">
        <slot name="icon">
          <v-icon v-if="typeof icon !== 'boolean'" :icon="icon" :color="iconColor"></v-icon>
        </slot>
      </div>
      <div class="d-flex flex-column">
        <v-card-title v-if="title" class="pa-0">
          <AppTypography v-if="title" type="headline-1">{{ title }}</AppTypography>
        </v-card-title>

        <div v-if="subtitle" :class="{ 'pa-0': true, 'mt-2': title !== '' }">
          <AppTypography type="body-1">{{ subtitle }}</AppTypography>
        </div>

        <AppTypography v-if="requirement" type="footnote-1" class="app-form-card__requirement">
          <span
            :class="{
              all: requirement === RequirementMetStatus.ALL,
              some: requirement === RequirementMetStatus.SOME,
              none: requirement === RequirementMetStatus.NONE,
            }"
          >
            {{ requirementLabel }}
          </span>
        </AppTypography>
      </div>
    </div>

    <v-card-text :class="{ 'pa-0': true, 'mt-5': !noGutter }">
      <slot />
    </v-card-text>

    <div v-if="footerText">
      <AppTypography type="body-1" class="mt-5">{{ footerText }}</AppTypography>
    </div>
  </v-card>
</template>

<style lang="scss" scoped>
.app-form-card {
  &__requirement {
    .all {
      font-weight: 500;
      color: #05704e;
    }
    .some {
      font-weight: 500;
      color: #f9a825;
    }
    .none {
      font-weight: 500;
      color: #f44336;
    }
  }
}

.app-form-card--shadow {
  box-shadow: 0px 8px 9px 0px #0000001a;
}

.v-card {
  border-radius: 16px;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 999px;
  flex-shrink: 0;
}
</style>
